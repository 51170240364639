/**
 * Jotem
 *
 * @author Robuust
 * @author Gijs Stegehuis <gijs@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { Navigation, Thumbs, Pagination } from 'swiper/modules';

Swiper.use([Navigation, Thumbs, Pagination]);

/**
 * Swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'slide', 'prev', 'next', 'project', 'pagination', 'syncSlider'];

  static values = {
    options: Object,
    customBullets: Array,
  };

  swiper;

  swiperSync;

  /**
   * Init.
   */
  initialize() {
    const swiperOptions = {
      slidesPerView: 'auto',
      spaceBetween: 40,
      on: {
        slideChange: (e) => {
          if (this.hasProjectTarget) {
            this.projectTarget.href = this.slideTargets[e.activeIndex].dataset.swiperUriParam;
          }
        },
      },
      ...this.optionsValue,
    };

    if (this.hasPrevTarget && this.hasNextTarget) {
      swiperOptions.navigation = {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      };
    }

    if (this.hasPaginationTarget) {
      swiperOptions.pagination = {
        el: this.paginationTarget,
        dynamicBullets: true,
        clickable: true,
        renderBullet: (index, className) => {
          const label = this.hasCustomBulletsValue
            ? this.customBulletsValue[index]
            : '';

          return `<span class="${className}">${label}</span>`;
        },
      };
    }

    if (this.hasSyncSliderTarget) {
      this.swiperSync = new Swiper(this.syncSliderTarget);

      swiperOptions.thumbs = {
        swiper: this.swiperSync,
      };
    }

    this.swiper = new Swiper(this.wrapperTarget, swiperOptions);
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
  }

  /**
   * Destroy.
   */
  disconnect() {
    this.swiper.destroy(true);
    this.swiper = undefined;

    if (this.swiperSync) {
      this.swiperSync.destroy(true);
      this.swiperSync = undefined;
    }
  }
}
