/**
 * Jotem
 *
 * @author Robuust
 * @author Rutger Bakker <rutger@robuust.digital>
 */

import { Controller } from '@hotwired/stimulus';
import Swiper from 'swiper';
import { EffectCoverflow, Navigation, Autoplay } from 'swiper/modules';

Swiper.use([EffectCoverflow, Navigation, Autoplay]);

/**
 * Stories swiper controller.
 */
export default class extends Controller {
  static targets = ['wrapper', 'video', 'prev', 'next', 'progress', 'mute'];

  swiper;

  /**
   * Init.
   */
  initialize() {
    const swiperOptions = {
      effect: 'coverflow',
      grabCursor: true,
      centeredSlides: true,
      slidesPerView: 'auto',
      coverflowEffect: {
        rotate: 50,
        stretch: 0,
        depth: 100,
        modifier: 1,
        slideShadows: true,
      },
    };

    if (this.hasPrevTarget && this.hasNextTarget) {
      swiperOptions.navigation = {
        prevEl: this.prevTarget,
        nextEl: this.nextTarget,
      };
    }

    this.swiper = new Swiper(this.wrapperTarget, swiperOptions);
  }

  /**
   * Mount.
   */
  connect() {
    this.swiper.enable();
    this.swiper.on('slideChangeTransitionEnd', this.slideChange.bind(this));
  }

  /**
   * Destroy.
   */
  disconnect() {
    this.swiper.off('slideChangeTransitionEnd', this.slideChange.bind(this));
    this.swiper.destroy(true);
    this.swiper = undefined;
  }

  /**
   * Toggle mute.
   */
  toggleMute() {
    if (this.hasVideoTarget) {
      this.videoTargets.forEach((video) => {
        const updatedVideo = video;
        updatedVideo.muted = !updatedVideo.muted;
        if (this.hasMuteTarget) this.muteTarget.classList.toggle('is-muted', updatedVideo.muted);
      });
    }
  }

  /**
   * Handle slide change.
   *
   * @param {Event} event
   */
  slideChange(event) {
    const { activeIndex, previousIndex, slides } = event;
    const nextIndex = activeIndex + 1;
    const isLastSlide = nextIndex === slides.length;

    // Start autoplay
    this.swiper.autoplay.start();

    // Pause previous slide video
    const previousVideo = this.videoTargets[previousIndex];
    if (previousVideo) {
      previousVideo.pause();
      previousVideo.currentTime = 0;
      previousVideo.removeEventListener('timeupdate', this.trackVideoProgress.bind(this));
      previousVideo.removeEventListener('ended', this.slideTo.bind(this, { params: { index: activeIndex } }));
      this.swiper.autoplay.resume();
    }

    // Play active slide video
    const activeVideo = this.videoTargets[activeIndex];
    if (activeVideo) {
      this.swiper.autoplay.pause();
      activeVideo.play();
      activeVideo.addEventListener('timeupdate', this.trackVideoProgress.bind(this));
      activeVideo.addEventListener('ended', this.slideTo.bind(this, { params: { index: isLastSlide ? 0 : nextIndex } }));
    }
  }

  /**
   * Go to slide.
   *
   * @param {Event} event
   */
  slideTo({ params }) {
    this.swiper.slideTo(params.index || 0);
  }

  /**
   * Track video progress.
   *
   * @param {Event} event
   */
  trackVideoProgress({ target: { currentTime, duration } }) {
    const progress = (currentTime / duration) * 100;

    if (this.hasProgressTarget) {
      this.progressTarget.style.setProperty('--progress', `${progress}%`);
    }
  }
}
